/* light */
@font-face {
  font-family: 'work-sans';
  src:
    url(/fonts/work-sans-300.woff2) format('woff2'),
    url(/fonts/work-sans-300.woff) format('woff');
  font-style: normal;
  font-weight: 300;
}

/* regular */
@font-face {
  font-family: 'work-sans';
  src:
    url(/fonts/work-sans-400.woff2) format('woff2'),
    url(/fonts/work-sans-400.woff) format('woff');
  font-style: normal;
  font-weight: 400;
}

/* medium */
@font-face {
  font-family: 'work-sans';
  src:
    url(/fonts/work-sans-500.woff2) format('woff2'),
    url(/fonts/work-sans-500.woff) format('woff');
  font-style: normal;
  font-weight: 500;
}

/* semi bold */
@font-face {
  font-family: 'work-sans';
  src:
    url(/fonts/work-sans-600.woff2) format('woff2'),
    url(/fonts/work-sans-600.woff) format('woff');
  font-style: normal;
  font-weight: 600;
}

* {
  box-sizing: border-box;
  /* The min-* style props are added due to an issue with react-slick
   * as well as nuka-carousel where those carousel components don't calculate
   * their dimensions properly when placed inside a flexbox container, see:
   * https://github.com/kenwheeler/slick/issues/982#issuecomment-73517458
   * https://stackoverflow.com/questions/26895349/how-can-i-get-ff-33-x-flexbox-behavior-in-ff-34-x
   * */

  min-width: 0;

  /* Commented "min-height: 0;" out since it's messing with flex and grid. 
   * Added specific fix below.
   * See this comment in the same thread: https://github.com/kenwheeler/slick/issues/982#issuecomment-688481448
   * */
  /* min-height: 0; */
}

.slick-slider * {
  min-height: 0;
  min-width: 0;
}

html,
body,
#root {
  height: 100%;
  background-color: #f4f7f8;
}

body {
  -webkit-print-color-adjust: exact !important;
}

button {
  background: transparent;
  border: 0;
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: inherit;
  width: auto;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
}

/*
Fixes code preview in style guide:
*/
[class^='rsg--root'] pre[aria-hidden] * {
  font-family: Consolas, 'Liberation Mono', Menlo, monospace !important;
  font-size: 13px !important;
  border-radius: 3px !important;
}

/* Remove excess padding and border in Firefox 4+ */
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ucc__toast {
  background: white;
  padding: 0;
}

.ucc__toast__body {
  margin: 0;
}

/**
 ** CSS for "src/components/atoms/loading-translations.js"
 ** This needs to be in global.css, styled components and its theme have not been initialized before i18next is initialized
 */
.ucc_atom_loading-translations {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #111857; /* theme.colors.text" from the "ucc" theme configured in config/theme.js */
  flex-direction: column;
  padding: 0 32px;
}

.ucc_atom_loading-translations--autopresentatie {
  color: #19273c; /* "theme.colors.text" from the "autopresentatie" theme configured in config/theme.js */
}

.ucc_atom_loading-translations--todrive {
  color: #2675c1; /* "theme.colors.text" from the "todrive" theme configured in config/theme.js */
}

.ucc_atom_loading-translations_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.ucc_atom_loading-translations_container--with-logo-offset {
  margin-top: -128px;
}

.ucc_atom_loading-translations_logo {
  width: 240px;
  height: auto;
}

.ucc_atom_loading-translations_content {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 20px 30px -20px rgba(0, 0, 0, 0.15);
  padding: 32px;
  background-color: white;
  gap: 16px;
}

@keyframes ucc-atom-loading-translations-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ucc_atom_loading-translations_spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 20px;
  min-width: 20px;
  position: relative;
  width: 20px;
  height: 20px;
}

.ucc_atom_loading-translations_spinner_ring {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 6px;
  border: 3px solid currentColor;
  border-radius: 50%;
  animation: ucc-atom-loading-translations-spinner-animation 1.2s
    cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }
  &:nth-child(2) {
    animation-delay: -0.3s;
  }
  &:nth-child(3) {
    animation-delay: -0.15s;
  }
}

.ucc_atom_loading-translations_content_paragraph {
  font-family: 'work-sans';
  margin: 0;
}

.ucc_atom_loading-translations_content_text {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

@media all and (min-width: 540px) {
  .ucc_atom_loading-translations_content_text {
    flex-direction: row;
  }
}
